<template>
  <v-container>
    <v-subheader class="mb-3">
      <v-btn
        icon
        small
        to="/"
      >
        <v-icon small>
          mdi-arrow-left
        </v-icon>
      </v-btn>
      <strong class="ml-2">Slots</strong>
    </v-subheader>
    <HomeSlots />
  </v-container>
</template>

<script>
import HomeSlots from '@/views/pages/Home_slots.vue'
export default {
  components: {
    HomeSlots
  },
  data: () => ({

  }),
  watch:{

  },
}
</script>